
import {defineComponent, watch, ref} from "vue";
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';
import { useStore } from "vuex";
import MonacoEditor from "@/components/MonacoEditor/MonacoEditor.vue";

export default defineComponent({
  name: "BasicFormItem",
  emits: ['changeOption'],
  components: {ExclamationCircleOutlined,MonacoEditor},
  props: {
    items: {},
    enumTypeTable: {},
    disabled: {
      default:false
    },
  },
  setup(props: any, context) {
    const store = useStore();
    const changeOption = (key: any) => {
      context.emit("changeOption",key)
    }

    return {
      formState:store.getters.getConfigFormState,
      changeOption
    }
  }
})
