import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_form_item = _resolveComponent("basic-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, _mergeProps({ class: "AutoForm" }, _ctx.$attrs, {
    ref: "formRef",
    rules: _ctx.rules,
    model: _ctx.formState,
    labelAlign: "left",
    "label-col": { span: 5 }
  }), {
    default: _withCtx(() => [
      _createVNode(_component_basic_form_item, {
        items: _ctx.items,
        enumTypeTable: _ctx.enumTypeTable,
        disabled: _ctx.disabled,
        onChangeOption: _ctx.changeOption
      }, null, 8, ["items", "enumTypeTable", "disabled", "onChangeOption"])
    ]),
    _: 1
  }, 16, ["rules", "model"]))
}