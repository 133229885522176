function getVData(item: any){
    const result: Array<any> = []
    const option = item.Options[item.V];
    const items = item.Options[item.V].Items;
    const VData = {
        Key: option.Key,
        Items: result
    }
    if (items) {
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const a: any = {
                Key: item.Key,
                V: item.V,
            }
            if (item.T == "Option") {
                a.VData = getVData(item)
            }
            result.push(a)
        }
    }
    return VData
}

export function getResultItems(items: any){
    const result: Array<any> = []
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const a: any = {
            Key: item.Key,
            V: item.V,
        }
        if (item.T == "Option") {
            a.VData = getVData(item)
        }
        result.push(a)
    }
    return result
}
