import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dc8d312"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_basic_form_item = _resolveComponent("basic-form-item", true)!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_MonacoEditor = _resolveComponent("MonacoEditor")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_ExclamationCircleOutlined = _resolveComponent("ExclamationCircleOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
      (item.T == 'Option')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_a_form_item, {
              label: item.Title,
              name: item.Key
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.formState[item.Key],
                  "onUpdate:value": ($event: any) => ((_ctx.formState[item.Key]) = $event),
                  onChange: ($event: any) => (_ctx.changeOption(item.Key)),
                  disabled: _ctx.disabled || item.disabled
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.Options, (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_radio, {
                        key: index,
                        value: index
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.Title), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["value", "onUpdate:value", "onChange", "disabled"])
              ]),
              _: 2
            }, 1032, ["label", "name"]),
            _createVNode(_component_basic_form_item, {
              items: item.Options[_ctx.formState[item.Key]]?.Items,
              enumTypeTable: _ctx.enumTypeTable
            }, null, 8, ["items", "enumTypeTable"])
          ], 64))
        : (_openBlock(), _createBlock(_component_a_form_item, {
            key: 1,
            label: item.Title,
            name: item.Key
          }, {
            default: _withCtx(() => [
              (item.T == 'String')
                ? (_openBlock(), _createBlock(_component_a_input, {
                    key: 0,
                    style: {"width":"95%"},
                    value: _ctx.formState[item.Key],
                    "onUpdate:value": ($event: any) => ((_ctx.formState[item.Key]) = $event),
                    autocomplete: "off",
                    disabled: _ctx.disabled || item.disabled
                  }, null, 8, ["value", "onUpdate:value", "disabled"]))
                : _createCommentVNode("", true),
              (item.T == 'Text')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (item.Key == 'JS' && item.Title == 'JS脚本')
                      ? (_openBlock(), _createBlock(_component_MonacoEditor, {
                          key: 0,
                          ref_for: true,
                          ref: "time_mEditor",
                          automaticLayout: true,
                          style: {"width":"95%","height":"160px"},
                          codes: _ctx.formState[item.Key],
                          "onUpdate:codes": ($event: any) => ((_ctx.formState[item.Key]) = $event)
                        }, null, 8, ["codes", "onUpdate:codes"]))
                      : (_openBlock(), _createBlock(_component_a_textarea, {
                          key: 1,
                          "auto-size": { minRows: 7 },
                          value: _ctx.formState[item.Key],
                          "onUpdate:value": ($event: any) => ((_ctx.formState[item.Key]) = $event),
                          style: {"width":"95%","resize":"none"},
                          disabled: _ctx.disabled || item.disabled
                        }, null, 8, ["value", "onUpdate:value", "disabled"]))
                  ], 64))
                : _createCommentVNode("", true),
              (item.T == 'Int32' || item.T == 'UInt64' || item.T == 'Double')
                ? (_openBlock(), _createBlock(_component_a_input_number, {
                    key: 2,
                    style: {"width":"95%"},
                    value: _ctx.formState[item.Key],
                    "onUpdate:value": ($event: any) => ((_ctx.formState[item.Key]) = $event),
                    disabled: _ctx.disabled || item.disabled
                  }, null, 8, ["value", "onUpdate:value", "disabled"]))
                : _createCommentVNode("", true),
              (item.T == 'Boolean')
                ? (_openBlock(), _createBlock(_component_a_switch, {
                    key: 3,
                    "checked-children": "是",
                    "un-checked-children": "否",
                    checked: _ctx.formState[item.Key],
                    "onUpdate:checked": ($event: any) => ((_ctx.formState[item.Key]) = $event),
                    disabled: _ctx.disabled || item.disabled
                  }, null, 8, ["checked", "onUpdate:checked", "disabled"]))
                : _createCommentVNode("", true),
              (item.T == 'Enum')
                ? (_openBlock(), _createBlock(_component_a_select, {
                    key: 4,
                    style: {"width":"50%"},
                    onChange: ($event: any) => (item.changeFun?item.changeFun($event,_ctx.items):''),
                    value: _ctx.formState[item.Key],
                    "onUpdate:value": ($event: any) => ((_ctx.formState[item.Key]) = $event),
                    disabled: _ctx.disabled || item.disabled
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enumTypeTable[item.EnumName], (em) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: em.Index,
                          value: em.Index
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(em.Title || em.Item), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["onChange", "value", "onUpdate:value", "disabled"]))
                : _createCommentVNode("", true),
              (item.T == 'showText')
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formState[item.Key]), 1))
                : _createCommentVNode("", true),
              (item.Des)
                ? (_openBlock(), _createBlock(_component_a_tooltip, { key: 6 }, {
                    title: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.Des), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_ExclamationCircleOutlined, { style: {"margin-left":"10px","color":"#29b8ff","font-size":"18px","position":"absolute","top":"7px"} })
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["label", "name"]))
    ], 64))
  }), 128))
}