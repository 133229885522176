
import {defineComponent, ref, onMounted,nextTick} from "vue";
import BasicFormItem from "@/components/common/BasicFormItem.vue";
import {useStore} from "vuex";

export default defineComponent({
  components: {BasicFormItem},
  setup(props: any, context) {
    const formRef = ref();
    const store = useStore();
    const items = ref<any>({})
    const enumTypeTable = ref<any>({})
    const rules = ref<any>({})
    const configSchema = ref<any>({})
    const formState = ref<any>(store.getters.getConfigFormState)
    const disabled = ref<boolean>()

    onMounted(() => {
      init()
    })
    const init = () => {
      configSchema.value = store.getters.getConfigParams.configSchema;
      items.value = configSchema.value?.Schema?.Items;
      enumTypeTable.value = configSchema.value?.EnumTypeTable;
      disabled.value = store.getters.getConfigParams.disabled;
      nextTick(()=>{
        initRules(items.value)
      })
    }

    const initRules = (items: any) => {
      items.forEach((key: any) => {
        if (!formState.value[key.Key]) {
          formState.value[key.Key] = key.V
        }
        if (key.IsReq == true) {
          if (key.T === "Option") {
            if(key.Options[key.V].Items){
              initRules(key.Options[key.V].Items)
            }
            return
          }
          rules.value[key.Key] = [
            {required: true, message: '请输入' + key.Title, trigger: 'change'},
          ]
          if (key.T === "Enum" || key.T === "Int32" || key.T === "UInt64" || key.T === "Double") {
            rules.value[key.Key][0].type = "number"
          }
          if (key.T === "Boolean") {
            rules.value[key.Key][0].type = "boolean"
          }
        }
      })
    }

    const coverItems = (items: any, changeKey?: any) => {
      items.forEach((key: any) => {
        if (key.Key == changeKey) {
          clearItems(key.Options[key.V].Items)
          key.V = formState.value[key.Key]
          return
        }

        if (formState.value[key.Key] != null) {
          key.V = formState.value[key.Key]
        }
        if (key.T === "Option") {
          if(key.Options[key.V].Items){
            coverItems(key.Options[key.V].Items, changeKey)
          }
        }
      })
    }

    //改变option时清除前值
    const clearItems = (items: any, changeKey?: any) => {
      items.forEach((key: any) => {
        if (formState.value[key.Key] != null) {
          delete formState.value[key.Key]
        }
      })
    }

    const changeOption = (key: any) => {
      coverItems(configSchema.value?.Schema?.Items, key)
      rules.value = {}
      initRules(configSchema.value?.Schema?.Items)
    }
    const validate = (key: any) => {
      return new Promise((resolve, reject) => {
        formRef.value
            .validate()
            .then((res: any) => {
              resolve(res)
            }).catch((err: any) => {
              reject(err)
            })
      })
    }
    const resetFields = (key: any) => {
      return formRef.value.resetFields(key)
    }

    return {
      formRef,
      configSchema,
      items,
      enumTypeTable,
      rules,
      formState,
      disabled,
      init,
      changeOption,
      coverItems,
      validate,
      resetFields,
    }
  }
})
